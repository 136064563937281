import { PermissionGroup } from "@/react/types";

const projectPermissions: PermissionGroup = {
  name: "Projects",
  key: "projects",
  description: null,
  permissions: {
    // cannot include `view` in the permission name as it is a reserved keyword for the permission group
    // and will automatically be checked if any of the other permissions are checked in this group
    scope_of_work_read: {
      name: "View Scope Of Work",
      key: "scope_of_work_permission_view",
      description: null,
    },
    scope_of_work_manage: {
      name: "Manage Scope Of Work",
      key: "scope_of_work_permission_manage",
      description: null,
      dependsOn: ["projects.scope_of_work_read"],
    },
    hardware_manage_orders: {
      name: "Manage Orders",
      key: "hardware_orders_permission_manage_orders",
      description: null,
    },
    hardware_order_approval: {
      name: "Order Approval",
      key: "hardware_orders_permission_order_approval",
      description: null,
      dependsOn: ["projects.hardware_manage_orders"],
    },
  },
};

export default projectPermissions;
